import moment from 'moment';
import { createCanvas } from 'canvas';
import i18n from '@/plugins/i18n';
import {
  imageScale,
  ValidatePatterns,
  InsuranceObject,
  InsuranceObjectDisplay,
  PaymentDisplay,
  ProductName,
  FirstPaymentDisplay,
  BaseInsurancePeriodLength,
  EarthquakeInsurancePeriodTypeCode,
  attachDocCodeDisplay,
} from '@/lib/const';
const pdfjs = require('pdfjs-dist/es5/build/pdf.js');
const pdfjsWorker = require('pdfjs-dist/es5/build/pdf.worker.entry.js');
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const getPdfBlobToB64 = async blob => {
  const reader = await getPdf(blob);

  return reader.result;

  // PDFの取得
  function getPdf(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader);
      reader.onerror = e => reject(e);
      reader.readAsDataURL(blob);
    });
  }
};

// 申込内容の画像化
export const getPdfImage = async b64 => {
  let base64Data = b64.replace(/^data:application\/pdf;base64,/, '');
  let decodePdf = new Buffer.from(base64Data.toString('utf-8'), 'base64');

  // PDFの読み込み
  const pdf = await pdfjs.getDocument({
    data: decodePdf,
    cMapUrl: '/pdfjs-dist/cmaps/', // 日本語の出力用のフォントファイル指定
    cMapPacked: true,
  }).promise;

  let images = [];
  // 1ページごとに画像をstoreに保存
  for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale: imageScale });

    const canvas = createCanvas();

    const canvasContext = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvasContext.height = viewport.height;
    canvasContext.width = viewport.width;

    let renderContext = {
      canvasContext,
      viewport,
    };

    await page.render(renderContext).promise;
    images[pageNum] = canvas.toDataURL();
  }

  return images;
};

// base64PDFの縦横取得
export const getPdfWidthAndHeight = async b64 => {
  let base64Data = b64.replace(/^data:application\/pdf;base64,/, '');
  let decodePdf = new Buffer.from(base64Data.toString('utf-8'), 'base64');

  // PDFの読み込み
  const pdf = await pdfjs.getDocument({
    data: decodePdf,
    cMapUrl: '/pdfjs-dist/cmaps/', // 日本語の出力用のフォントファイル指定
    cMapPacked: true,
  }).promise;
  const page = await pdf.getPage(1);

  return {
    width: page.view[2],
    height: page.view[3],
  };
};

// base64画像の縦横取得
export const getImgWidthAndHeight = async b64 => {
  const image = await getImage(b64);
  return {
    width: image.width,
    height: image.height,
  };

  function getImage(src) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = e => reject(e);
      image.src = src;
    });
  }
};

// Blob を base64 に変換する
export const convertBlobToBase64 = async blob => {
  const reader = await readFile(blob);
  return reader.result;

  function readFile(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader);
      reader.onerror = e => reject(e);
      reader.readAsDataURL(blob);
    });
  }
};

/**
 * 改行表示処理
 * @param {String} message 改行対象メッセージ
 */
export const splitMessageLine = function(message) {
  return message ? message.split(/\r?\n/g) : null;
};

/**
 * パターン検証共通処理
 * @param {Any} value 値
 * @param {String} label バリデーション種類名
 */
export const validateValue = function(value, label) {
  if (!ValidatePatterns[label].pattern.test(value)) {
    return `${ValidatePatterns[label].type}のみ入力可能です`;
  }

  return true;
};

/**
 * 保険の対象を「・」で結合
 * @param {Number} insuranceObject1 保険の対象1
 * @param {Number} insuranceObject2 保険の対象2
 */
export const convertInsuranceObject = function(
  insuranceObject1,
  insuranceObject2
) {
  const insuranceObject = [];
  if (insuranceObject1) {
    insuranceObject.push(InsuranceObjectDisplay[insuranceObject1]);
  }
  if (insuranceObject2) {
    insuranceObject.push(InsuranceObjectDisplay[insuranceObject2]);
  }
  return Object.keys(insuranceObject).length
    ? insuranceObject.join('・')
    : 'ー';
};

// メッセージ置換
export const replaceMessage = async (text, contractInfo) => {
  if (text.match('{insuranceName}')) {
    text = text.replace(/{insuranceName}/g, contractInfo.insuranceName);
  }
  if (text.match('{policyNumber}')) {
    text = text.replace(/{policyNumber}/g, contractInfo.policyNumber);
  }
  if (text.match('{fullNameKanji}')) {
    text = text.replace(/{fullNameKanji}/g, contractInfo.fullNameKanji);
  }
  if (text.match('{insuranceLocation}')) {
    text = text.replace(/{insuranceLocation}/g, contractInfo.insuranceLocation);
  }
  if (text.match('{startDate}')) {
    text = text.replace(/{startDate}/g, contractInfo.startDate);
  }
  if (text.match('{lastAccessibleDay}')) {
    text = text.replace(/{lastAccessibleDay}/g, contractInfo.lastAccessibleDay);
  }
  if (text.match('{dueDate}')) {
    text = text.replace(/{dueDate}/g, contractInfo.dueDate);
  }
  if (text.match('{agencyName}')) {
    text = text.replace(/{agencyName}/g, contractInfo.agencyName);
  }
  if (text.match('{chargePersonName}')) {
    text = text.replace(/{chargePersonName}/g, contractInfo.chargePersonName);
  }
  if (text.match('{agencyPhoneNumber}')) {
    text = text.replace(/{agencyPhoneNumber}/g, contractInfo.agencyPhoneNumber);
  }
  if (text.match('{officialWebSiteUrl}')) {
    const officialWebSiteUrl = contractInfo.officialWebSiteUrl;
    text = text.replace(/{officialWebSiteUrl}/g, officialWebSiteUrl);
  }
  if (text.match('{officialLineUrl}')) {
    const officialLineUrl = contractInfo.officialLineUrl;
    text = text.replace(/{officialLineUrl}/g, officialLineUrl);
  }
  return text;
};

// 不特定情報のメッセージ置換
// 秘匿情報: contractUrl
// 契約後に確定される修正内容情報: modificationContent
export const replaceUnspecifiedInfo = async (text, contractInfo) => {
  if (text.match('{contractUrl}')) {
    const contractUrl = contractInfo.contractUrl;
    text = text.replace(/{contractUrl}/g, contractUrl);
  }
  if (text.match('{modificationContent}')) {
    text = text.replace(
      /{modificationContent}/g,
      contractInfo.modificationContent
    );
  }
  return text;
};

/**
 * 円単位をカンマ区切り
 * @param {Number} 日本円
 */
export const convertJapaneseYenToCommaSeparated = function(japaneseYen) {
  return japaneseYen
    ? `${String(japaneseYen).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円`
    : 'ー';
};

/**
 * 円単位を千円単位に変換した上でカンマ区切り
 * @param {Number} 日本円
 */
export const convertJapaneseYenToThousandYen = function(japaneseYen) {
  if (japaneseYen) {
    if (String(japaneseYen).length >= 4) {
      const thousandYen = Math.floor(japaneseYen / 1000);
      return `${String(thousandYen).replace(
        /(\d)(?=(\d\d\d)+(?!\d))/g,
        '$1,'
      )}千円`;
    } else {
      return convertJapaneseYenToCommaSeparated(japaneseYen);
    }
  } else {
    return 'ー';
  }
};

// 日付を和暦に変換する
const convertDateToJapaneseCalender = date => {
  // 元号の情報
  const jaCalender = [
    {
      era: '明治',
      start: '1868/1/25',
    },
    {
      era: '大正',
      start: '1912/7/30',
    },
    {
      era: '昭和',
      start: '1926/12/25',
    },
    {
      era: '平成',
      start: '1989/1/8',
    },
    {
      era: '令和',
      start: '2019/5/1',
    },
  ];

  for (let i = jaCalender.length - 1; i >= 0; i--) {
    const targetDate = moment(jaCalender[i]['start'], 'YYYY/M/D');
    // 元号の範囲に入っている場合（令和より後の場合は令和とする）
    if (date.isSameOrAfter(targetDate)) {
      return (
        jaCalender[i]['era'] +
        (date.year() - targetDate.year() + 1) +
        '年' +
        date.format('M月D日')
      );
    }
    // 元号の範囲に入らなかった場合（明治より前の場合は空欄とする）
    if (i <= 0) {
      return '';
    }
  }
};

// 生年月日の変換
export const convertDateOfBirth = birthDate => {
  if (!birthDate) return '';

  const date = moment(birthDate);
  // 西暦
  const dateStr1 = date.format('YYYY年M月D日');
  // 和暦
  const dateStr2 = convertDateToJapaneseCalender(date);

  return dateStr2 ? `${dateStr1}（${dateStr2}）` : dateStr1; // 西暦表示（和暦表示）
};

// 日付の変換
export const convertDate = date => {
  if (!date) return '';

  const targetDate = moment(date);
  // 西暦
  const dateStr1 = targetDate.format('YYYY年M月D日');
  // 和暦
  const dateStr2 = convertDateToJapaneseCalender(targetDate);

  return dateStr2 ? `${dateStr1}（${dateStr2}）` : dateStr1; // 西暦表示（和暦表示）
};

/**
 * ファイルダウンロード
 * @param blob ダウンロードファイルバイナリーデータ
 * @param fileName ダウンロードファイル名
 */
export const downloader = function(blob, fileName) {
  // IEかチェック
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
};
