var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        width: _vm.width,
        "max-width": _vm.maxWidth ? _vm.maxWidth : "35vw"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "justify-center white--text", class: _vm.color },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _c("v-divider"),
          _vm._t("default"),
          _c(
            "v-card-actions",
            { staticClass: "justify-space-around pb-6" },
            [_vm._t("footer")],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }