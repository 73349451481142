<template>
  <v-app-bar clipped-left color="header" height="auto">
    <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
    <v-img src="@/assets/img/logo.gif" max-width="170px" min-width="170px" />
    <v-col
      ><v-row justify="end" class="pl-4">{{ officeName }}</v-row></v-col
    >
    <v-col>
      <v-row justify="end" class="pl-4">{{
        $t('label.insurenceStartdate')
      }}</v-row>
    </v-col>
    <v-toolbar-items>
      <v-menu bottom left offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn class="text-none" text v-on="on">
            <v-icon>mdi-account</v-icon>
            {{ userName }}
            <v-icon>keyboard_arrow_down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="link in toolbarLinks"
            :key="link.title"
            @click="link.click"
          >
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <v-btn
      class="font-weight-black float-right mr-4"
      align="center"
      width="70vw"
      max-width="100px"
      color="next"
      style="font-size:15px"
      dark
      @click="onLogoutClick"
      >{{ $t('label.logout') }}</v-btn
    >
    <v-img
      src="@/assets/img/pdMark.gif"
      max-width="30px"
      min-width="30px"
      class="float-right"
      height="auto"
    />
    <PasswordChangeDialog
      @onSuccess="onSuccessPasswordChange"
      ref="passwordChangeDialog"
    ></PasswordChangeDialog>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Role } from '@/lib/role';
import PasswordChangeDialog from '@/components/organisms/agency/PasswordChangeDialog';

export default {
  name: 'Header',
  components: { PasswordChangeDialog },
  data() {
    return {};
  },
  methods: {
    ...mapActions('ui', ['toggleDrawer']),
    ...mapGetters('user', ['userDetail']),

    onClickEditUserInfo() {
      // ログインユーザーの情報編集に遷移
      const path = '/edit_user_info/me';
      if (this.$route.path !== path) {
        // 遷移先と同じページからは遷移しない（冗長回避エラーのため）
        this.$router.push(path);
      }
    },

    onClickEditAgencyInfoForIndividual() {
      // ログインユーザーの情報編集に遷移
      const path = '/edit_agency_info_for_individual/me';
      if (this.$route.path !== path) {
        // 遷移先と同じページからは遷移しない（冗長回避エラーのため）
        this.$router.push(path);
      }
    },

    onClickEditPassword() {
      // パスワード入力欄を初期化
      this.$refs.passwordChangeDialog.init();
      // パスワード変更ダイアログを開く
      this.$refs.passwordChangeDialog.open();
    },
    async onSuccessPasswordChange(condition) {
      // パスワード更新処理
      const result = await this.$cognito
        .changePassword(condition.currentPassword, condition.newPassword)
        .catch(err => {
          // バリデーションエラーをダイアログに表示
          this.$refs.passwordChangeDialog.onErrorUpdate(
            this.$t(`error.passwordChangeError.${err.code}`)
          );
        });
      // パスワード更新成功時にダイアログを表示
      if (result) {
        this.$refs.passwordChangeDialog.close();
        this.$refs.passwordChangeDialog.onUpdateSuccess();
      }
    },

    async onLogoutClick() {
      await this.$cognito
        .logout()
        .then(() => {
          this.$set(this, 'showSignOutDialog', false);
          // ログイン画面に遷移
          this.$router.push('/login');
        })
        .catch(error => {
          console.error(error);
          this.$cognito.clearStorage();
          // ログイン画面に遷移
          this.$router.push('/login');
        });
    },
  },
  mounted() {},
  computed: {
    toolbarLinks() {
      const isShowEditAgencyInfoForIndividualLink = Role.isButtonEnabled(
        this.userDetail(),
        'editAgencyInfoForIndividual'
      );

      const links = [
        {
          title: 'editUserInfo',
          name: this.$t('link.editLoginUserInfo'),
          click: this.onClickEditUserInfo,
        },
        {
          title: 'editPassword',
          name: this.$t('link.passwordChange'),
          click: this.onClickEditPassword,
        },
      ];

      if (isShowEditAgencyInfoForIndividualLink) {
        links.push({
          title: 'EditAgencyInfoForIndividual',
          name: this.$t('link.editAgencyInfoForIndividual'),
          click: this.onClickEditAgencyInfoForIndividual,
        });
      }
      return links;
    },
    userName() {
      return this.userDetail().userName ? this.userDetail().userName : '';
    },
    officeName() {
      return this.userDetail().officeName ? this.userDetail().officeName : '';
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
