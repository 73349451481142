import i18n from '@/plugins/i18n';

export const BaseName = {
  contractor: 'contractor',
  agency: 'agency',
};

// 被保険者DNS設定
export const BasePaths = BaseName.agency;

// API接続先
export const baseURL = process.env.VUE_APP_API_BASE_URL;

// Cognito接続先
export const CognitoSettings = {
  UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  Storage: localStorage,
};

// 公式URL
export const OfficialUrl = {
  ContractUrlForCountingBytes: process.env.VUE_APP_CONTRACTOR_BASE_URL,
  OfficialWebSite: process.env.VUE_APP_SOMPO_JAPAN_OFFICIAL_WEB_SITE,
  OfficialLineUrl: process.env.VUE_APP_SOMPO_JAPAN_OFFICIAL_LINE_URL,
};

export const UserIdKey = 'CurretnUser';

// エラーコード
export const ErrorCode = {
  UserLoginIdExists: 'user login id exists', // ユーザーログインID既存
  MasterNotFound: 'master not found', // マスターが存在しない
  CaseDataError: {
    dataFileNotFound: 'case data not found', // 案件データファイルがない
    invalidDataFileFormat: 'case data file is invalid', // 案件データファイルの形式が違う
    invalidData: 'data is invalid', // ファイル内容が不正
    applicationFormRecordNotFound: 'application form record not found', // 対応する申込内容レコードがない
  },
  NotAgency: 'na01',
  NotSales: 'ns01',
  DuplicateAgencyCode: 'ia01',
  DuplicateSalesCode: 'is01',
};

// 営業店一覧テーブルヘッダ
export const salesListTableHeader = [
  {
    text: i18n.t('header.salesListTable.detail'),
    sortable: false,
    value: 'detail',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.salesListTable.salesCode'),
    sortable: true,
    value: 'salesCode',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.salesListTable.salesDepartmentName'),
    sortable: false,
    value: 'salesDepartmentName',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.salesListTable.salesSectionName'),
    sortable: false,
    value: 'salesSectionName',
    align: 'center',
    divider: false, // 一番右の項目
    width: '10vw',
  },
];

// 代理店一覧テーブルヘッダ
export const AgencyListTableHeader = [
  {
    text: i18n.t('header.agencyListTable.detail'),
    sortable: false,
    value: 'detail',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.agencyListTable.agencyCode'),
    sortable: true,
    value: 'agencyCode',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.agencyListTable.agencyName'),
    sortable: false,
    value: 'agencyName',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.salesListTable.salesCode'),
    sortable: false,
    value: 'salesCode',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.salesListTable.salesDepartmentName'),
    sortable: false,
    value: 'salesDepartmentName',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.salesListTable.salesSectionName'),
    sortable: false,
    value: 'salesSectionName',
    align: 'center',
    divider: false, // 一番右の項目
    width: '10vw',
  },
];

// 代理店一括登録確認テーブルヘッダ
export const AgencyConfirmTableHeader = [
  {
    text: i18n.t('header.agencyListTable.agencyCode'),
    sortable: false,
    value: 'agencyCode',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.agencyListTable.agencyName'),
    sortable: false,
    value: 'agencyName',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.agencyListTable.salesCode'),
    sortable: false,
    value: 'salesCode',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.agencyListTable.salesDepartmentName'),
    sortable: false,
    value: 'salesDepartmentName',
    align: 'center',
    divider: true,
    width: '10vw',
  },
  {
    text: i18n.t('header.agencyListTable.salesSectionName'),
    sortable: false,
    value: 'salesSectionName',
    align: 'center',
    divider: false, // 一番右の項目
    width: '10vw',
  },
];

// ユーザー一括インポート種別
export const UserBulkImportType = {
  CreateSales: 'createSales',
  CreateAgency: 'createAgency',
  UpdateSales: 'updateSales',
  UpdateAgency: 'updateAgency',
};

// ユーザー一括登録確認テーブルヘッダ
export const UserConfirmTableHeader = [
  {
    text: i18n.t('header.userListTable.loginId'),
    sortable: false,
    value: 'loginId',
    align: 'center',
    divider: true,
    width: '10%',
    importType: [
      UserBulkImportType.UpdateSales,
      UserBulkImportType.UpdateAgency,
    ],
  },
  {
    text: i18n.t('header.userListTable.userName'),
    sortable: false,
    value: 'userName',
    align: 'center',
    divider: true,
    width: '10%',
    importType: '*',
  },
  {
    text: i18n.t('header.userListTable.userMail'),
    sortable: false,
    value: 'userMail',
    align: 'center',
    divider: true,
    width: '10%',
    importType: '*',
  },
  {
    text: i18n.t('header.userListTable.salesCode'),
    sortable: false,
    value: 'salesCode',
    align: 'center',
    divider: true,
    width: '10%',
    importType: [
      UserBulkImportType.CreateSales,
      UserBulkImportType.UpdateSales,
    ],
  },
  {
    text: i18n.t('header.userListTable.agencyCode'),
    sortable: false,
    value: 'agencyCode',
    align: 'center',
    divider: true,
    width: '10%',
    importType: [
      UserBulkImportType.CreateAgency,
      UserBulkImportType.UpdateAgency,
    ],
  },
  {
    text: i18n.t('header.userListTable.recruiterId'),
    sortable: false,
    value: 'recruiterId',
    align: 'center',
    divider: true,
    width: '10%',
    importType: '*',
  },
  {
    text: i18n.t('header.userListTable.chargePersonName'),
    sortable: false,
    value: 'chargePersonName',
    align: 'center',
    divider: true,
    width: '10%',
    importType: [
      UserBulkImportType.CreateAgency,
      UserBulkImportType.UpdateAgency,
    ],
  },
  {
    text: i18n.t('header.userListTable.chargePersonPhoneNumber'),
    sortable: false,
    value: 'chargePersonPhoneNumber',
    align: 'center',
    divider: true,
    width: '10%',
    importType: [
      UserBulkImportType.CreateAgency,
      UserBulkImportType.UpdateAgency,
    ],
  },
  {
    text: i18n.t('header.userListTable.role'),
    sortable: false,
    value: 'role',
    align: 'center',
    divider: true,
    width: '10%',
    importType: [
      UserBulkImportType.CreateAgency,
      UserBulkImportType.UpdateAgency,
    ],
  },
  {
    text: i18n.t('header.userListTable.startDate'),
    sortable: false,
    value: 'startDate',
    align: 'center',
    divider: true,
    width: '10%',
    pattern: 'register',
    importType: [
      UserBulkImportType.CreateSales,
      UserBulkImportType.CreateAgency,
    ],
  },
  {
    text: i18n.t('header.userListTable.comment'),
    sortable: false,
    value: 'comment',
    align: 'center',
    divider: false, // 一番右の項目
    width: '10%',
    importType: '*',
  },
];

// ユーザー一覧テーブルヘッダ
export const UserListTableHeader = [
  {
    text: i18n.t('header.userListTable.detail'),
    sortable: false,
    value: 'detail',
    align: 'center',
    divider: true,
    width: '5%',
  },
  {
    text: i18n.t('header.userListTable.loginId'),
    sortable: false,
    value: 'loginId',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.userName'),
    sortable: false,
    value: 'userName',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.userMail'),
    sortable: false,
    value: 'userMail',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.salesCode'),
    sortable: false,
    value: 'salesCode',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.salesDepartmentName'),
    sortable: false,
    value: 'salesDepartmentName',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.salesSectionName'),
    sortable: false,
    value: 'salesSectionName',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.agencyCode'),
    sortable: false,
    value: 'agencyCode',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.agencyName'),
    sortable: false,
    value: 'agencyName',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.chargePersonName'),
    sortable: false,
    value: 'chargePersonName',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.recruiterId'),
    sortable: false,
    value: 'recruiterId',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.role'),
    sortable: false,
    value: 'role',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.lastUpdatedDate'),
    sortable: false,
    value: 'lastUpdatedDate',
    class: 'text-center',
    align: 'right',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.comment'),
    sortable: false,
    value: 'comment',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '11%',
  },
  {
    text: i18n.t('header.userListTable.isDeleted'),
    sortable: false,
    value: 'isDeleted',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.userListTable.lastLoginDate'),
    sortable: false,
    value: 'lastLoginDate',
    class: 'text-center',
    align: 'right',
    divider: false, // 一番右の項目
    width: '6%',
  },
];

// 案件一覧テーブルヘッダ
export const ContractListTableHeader = [
  {
    text: i18n.t('header.contractListTable.detail'),
    sortable: false,
    value: 'detail',
    align: 'center',
    divider: true,
    width: '5%',
  },
  {
    text: i18n.t('header.contractListTable.policyNumber'),
    sortable: false,
    value: 'policyNumber',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '7%',
  },
  {
    text: i18n.t('header.contractListTable.previousPolicyNumber'),
    sortable: false,
    value: 'previousPolicyNumber',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '7%',
  },
  {
    text: i18n.t('header.contractListTable.printingSerialNumber'),
    sortable: false,
    value: 'printingSerialNumber',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '7%',
  },
  {
    text: i18n.t('header.contractListTable.fullName'),
    sortable: false,
    value: 'fullName',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '7%',
  },
  {
    text: i18n.t('header.contractListTable.fullNameKana'),
    sortable: false,
    value: 'fullNameKana',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '7%',
  },
  {
    text: i18n.t('header.contractListTable.startDate'),
    sortable: true,
    value: 'startDate',
    class: 'text-center',
    align: 'right',
    divider: true,
    width: '7%',
  },
  {
    text: i18n.t('header.contractListTable.status'),
    sortable: true,
    value: 'status',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.contractListTable.startStatus'),
    sortable: false,
    value: 'startStatus',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.contractListTable.note'),
    sortable: false,
    value: 'note',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '15%',
  },
  {
    text: i18n.t('header.contractListTable.agencyCode'),
    sortable: false,
    value: 'contractAgencyCode',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '5%',
  },
  {
    text: i18n.t('header.contractListTable.agencyCodeUser'),
    sortable: false,
    value: 'agencyAgencyCode',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '5%',
  },
  {
    text: i18n.t('header.contractListTable.salesCode'),
    sortable: false,
    value: 'salesCode',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '5%',
  },
  {
    text: i18n.t('header.contractListTable.chargePersonName'),
    sortable: false,
    value: 'staffName',
    class: 'text-center',
    align: 'left',
    divider: true,
    width: '6%',
  },
  {
    text: i18n.t('header.contractListTable.latestUploadDate'),
    sortable: true,
    value: 'latestUploadDate',
    class: 'text-center',
    align: 'right',
    divider: false, // 一番右の項目
    width: '8%',
  },
];

// プラン一覧テーブルヘッダ
export const PlanListTableHeader = [
  {
    text: i18n.t('header.planListTable.planName'),
    sortable: false,
    value: 'planName',
    align: 'center',
    divider: true,
    width: '10%',
  },
  {
    text: i18n.t('header.planListTable.insurancePeriodText'),
    sortable: false,
    value: 'insurancePeriodText',
    align: 'center',
    divider: true,
    width: '9%',
  },
  {
    text: i18n.t('header.planListTable.earthquakeInsurancePeriodText'),
    sortable: false,
    value: 'earthquakeInsurancePeriodText',
    align: 'center',
    divider: true,
    width: '10%',
  },
  {
    text: i18n.t('header.planListTable.totalPrice'),
    sortable: false,
    value: 'totalPrice',
    align: 'center',
    divider: true,
    width: '8%',
  },
  {
    text: i18n.t('header.planListTable.eachTimePrice'),
    sortable: false,
    value: 'eachTimePrice',
    align: 'center',
    divider: true,
    width: '8%',
  },
  {
    text: i18n.t('header.planListTable.paymentTypeName'),
    sortable: false,
    value: 'paymentTypeName',
    align: 'center',
    divider: true,
    width: '10%',
  },
  {
    text: i18n.t('header.planListTable.message'),
    sortable: false,
    value: 'message',
    align: 'center',
    divider: true,
    width: '20%',
  },
  {
    text: i18n.t('header.planListTable.comment'),
    sortable: false,
    value: 'comment',
    align: 'center',
    divider: false, // 一番右の項目
    width: '20%',
  },
];

// ユーザーインポート一覧
export const UserImportListHeaders = [
  {
    text: i18n.t('header.userImportListHeaders.detail'),
    sortable: false,
    value: 'detail',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.userImportListHeaders.userName'),
    sortable: false,
    value: 'userName',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.userImportListHeaders.officeType'),
    sortable: false,
    value: 'officeType',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.userImportListHeaders.importDate'),
    sortable: false,
    value: 'importDate',
    align: 'center',
    divider: false, // 一番右の項目
  },
];

export const ExtraFileListHeaders = [
  {
    text: i18n.t('header.ExtraFileListHeaders.detail'),
    sortable: false,
    value: 'detail',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.ExtraFileListHeaders.name'),
    sortable: false,
    value: 'name',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.ExtraFileListHeaders.comment'),
    sortable: false,
    value: 'comment',
    align: 'center',
    divider: true,
  },
];

// 保険種別一覧テーブルヘッダ
export const InsuranceTypeListTableHeader = [
  {
    text: i18n.t('header.insuranceTypeListTable.edit'),
    sortable: false,
    value: 'edit',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.insuranceTypeListTable.name'),
    sortable: false,
    value: 'name',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.insuranceTypeListTable.fireFlg'),
    sortable: false,
    value: 'fireFlg',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.insuranceTypeListTable.latestUpdate'),
    sortable: false,
    value: 'latestUpdate',
    align: 'center',
    divider: false, // 一番右の項目
  },
];

// 重要事項等説明書一覧テーブルヘッダ
export const ImportantMatterListTableHeader = [
  {
    text: i18n.t('header.importantMatterListTable.edit'),
    sortable: false,
    value: 'edit',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.importantMatterListTable.productName'),
    sortable: false,
    value: 'productName',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.importantMatterListTable.name'),
    sortable: false,
    value: 'name',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.importantMatterListTable.insuranceName'),
    sortable: false,
    value: 'insuranceName',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.importantMatterListTable.startDate'),
    sortable: false,
    value: 'startDate',
    align: 'center',
    divider: true,
  },
  {
    text: i18n.t('header.importantMatterListTable.latestUpdate'),
    sortable: false,
    value: 'latestUpdate',
    align: 'center',
    divider: false, // 一番右の項目
  },
];

// バリデーション用文字パターン
export const ValidatePatterns = {
  kana: {
    type: '半角ｶﾀｶﾅ',
    pattern: /^[ｦ-ﾟ]*$/,
  },
  telNumberLength: {
    type: '半角数字10〜11桁',
    pattern: /^(\d{10,11})$/,
  },
  mailAddress: {
    type: '正しいメールアドレス',
    // 全角文字を弾く
    // @の前に1文字以上・後に3文字以上文字を入力
    pattern: /^[A-Za-z0-9._+-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9-]+)+?$/,
  },
  agencyCode: {
    type: i18n.t('validation.agencyCode'),
    pattern: /^[A-Za-z0-9]{5}$/, // 半角英数字5桁
  },
  salesCode: {
    type: i18n.t('validation.salesCode'),
    pattern: /^[A-Za-z0-9]{4}$/, // 半角英数字4桁
  },
  recruiterId: {
    type: i18n.t('validation.recruiterId'),
    pattern: /^[A-Za-z0-9]+$/, // 半角英数字10桁
  },
  formId: {
    type: i18n.t('validation.formId'),
    pattern: /^[0-9]{5}$/, // 半角数字5桁
  },
  date: {
    type: i18n.t('validation.date'),
    pattern: /^[0-9]{8}$/, // 半角数字8桁
  },
};

// ヘッダー非表示画面一覧
export const nonHeaderPathName = {
  agency: ['Login', 'Maintenance'],
};

// 案件一覧表示のデフォルト設定
export const ContractListDisplayDefault = {
  page: 1, // 初回表示ページ
  itemsPerPage: 10, // 1ページあたりの表示件数
  itemsPerPageOptions: [10, 15, 20], // 1ページあたりの表示件数の選択肢
};

// 保険種別一覧表示のデフォルト設定
export const InsuranceTypeListDisplayDefault = {
  page: 1, // 初回表示ページ
  itemsPerPage: 10, // 1ページあたりの表示件数
  itemsPerPageOptions: [10, 15, 20], // 1ページあたりの表示件数の選択肢
};

// 重要事項等説明書一覧表示のデフォルト設定
export const ImportantMatterListDisplayDefault = {
  page: 1, // 初回表示ページ
  itemsPerPage: 10, // 1ページあたりの表示件数
  itemsPerPageOptions: [10, 15, 20], // 1ページあたりの表示件数の選択肢
};

// 営業店一覧表示のデフォルト設定
export const salesListDisplayDefault = {
  page: 1, // 初回表示ページ
  itemsPerPage: 10, // 1ページあたりの表示件数
  itemsPerPageOptions: [10, 15, 20], // 1ページあたりの表示件数の選択肢
};

// 代理店一覧表示のデフォルト設定
export const agencyListDisplayDefault = {
  page: 1, // 初回表示ページ
  itemsPerPage: 10, // 1ページあたりの表示件数
  itemsPerPageOptions: [10, 15, 20], // 1ページあたりの表示件数の選択肢
};

// ユーザー一覧表示のデフォルト設定
export const userListDisplayDefault = {
  page: 1, // 初回表示ページ
  itemsPerPage: 10, // 1ページあたりの表示件数
  itemsPerPageOptions: [10, 15, 20], // 1ページあたりの表示件数の選択肢
};

// ユーザーインポート一覧表示のデフォルト設定
export const UserImportListDisplayDefault = {
  page: 1, // 初回表示ページ
  itemsPerPage: 10, // 1ページあたりの表示件数
  itemsPerPageOptions: [10, 15, 20], // 1ページあたりの表示件数の選択肢
};

// ユーザーステータス
export const UserStatus = {
  Activated: 1, // 有効
  Inactivated: 2, // 無効
};

// ユーザー削除ステータス
export const UserDeleteStatus = {
  NotDeleted: 1, // 未廃止
  Deleted: 2, // 廃止済
};

// ユーザー削除ステータスの表示値
export const UserDeleteStatusDisplay = {
  [UserDeleteStatus.NotDeleted]: '',
  [UserDeleteStatus.Deleted]: i18n.t('label.isDeleted.deleted'),
};

// 案件ステータス
export const ContractStatus = {
  Processing: 1, // 未送信
  Waiting: 2, // お客様確認待ち
  Concluded: 3, // 締結済
  Accounted: 9, // 計上完了
  AccountedWithDocRequired: 10, // 計上完了(必要書類送付要)
  AccountingError: 11, // 計上エラー
  UnAccounted: 12, //計上処理実施待ち
  Withdrawal: 13, // 取り下げ
};

// 案件ステータス変更イベント
export const ContractStatusChangeEvent = {
  ConfirmWithdrawal: 'confirmWithdrawal',
  ConfirmWithdrawalWithNotify: 'confirmWithdrawalWithNotify',
  ConfirmAccounted: 'confirmAccounted',
  ConfirmAccountedWithDocRequired: 'confirmAccountedWithDocRequired',
};

// 案件ステータス編集マップ
export const ContractStatusEditMap = {
  Accept: {
    [ContractStatus.Processing]: {
      [ContractStatus.Withdrawal]: ContractStatusChangeEvent.ConfirmWithdrawal,
    },
    [ContractStatus.Waiting]: {
      [ContractStatus.Withdrawal]:
        ContractStatusChangeEvent.ConfirmWithdrawalWithNotify,
    },
    [ContractStatus.Concluded]: {},
    [ContractStatus.Accounted]: {},
    [ContractStatus.AccountedWithDocRequired]: {
      [ContractStatus.Accounted]: ContractStatusChangeEvent.ConfirmAccounted,
    },
    [ContractStatus.AccountingError]: {
      [ContractStatus.Withdrawal]:
        ContractStatusChangeEvent.ConfirmWithdrawalWithNotify,
    },
    [ContractStatus.UnAccounted]: {
      [ContractStatus.Accounted]: ContractStatusChangeEvent.ConfirmAccounted,
      [ContractStatus.AccountedWithDocRequired]:
        ContractStatusChangeEvent.ConfirmAccountedWithDocRequired,
    },
    [ContractStatus.Withdrawal]: {},
  },
};

// 案件ステータスの表示値
export const ContractStatusDisplay = {
  [ContractStatus.Processing]: i18n.t('label.contractStatus.processing'),
  [ContractStatus.Waiting]: i18n.t('label.contractStatus.waiting'),
  [ContractStatus.Concluded]: i18n.t('label.contractStatus.concluded'),
  [ContractStatus.Accounted]: i18n.t('label.contractStatus.accounted'),
  [ContractStatus.AccountedWithDocRequired]: i18n.t(
    'label.contractStatus.accountedWithDocRequired'
  ),
  [ContractStatus.AccountingError]: i18n.t(
    'label.contractStatus.accountingError'
  ),
  [ContractStatus.UnAccounted]: i18n.t('label.contractStatus.unAccounted'),
  [ContractStatus.Withdrawal]: i18n.t('label.contractStatus.withdrawal'),
};

// 案件閲覧ステータス
export const ContractReadStatus = {
  Available: 1, // 閲覧可
  HasNotAccess: 2, // 閲覧不可
  HasClosed: 3, // 手続き不可
  Withdrawal: 4, // 取り下げ
  Deleted: 5, // 削除
};

// 案件の有効期限
export const ContractExpiration = {
  [ContractReadStatus.HasNotAccess]: 3, // 月
  [ContractReadStatus.HasClosed]: 0, // 日
};

// 事業所種別
export const OfficeType = {
  Head: 1, // 本社
  Sales: 2, // 営業店
  Agency: 3, // 代理店
};

// 事業所種別の表示値
export const OfficeTypeDisplay = {
  [OfficeType.Head]: i18n.t('label.officeType.head'),
  [OfficeType.Sales]: i18n.t('label.officeType.sales'),
  [OfficeType.Agency]: i18n.t('label.officeType.agency'),
};

// 権限タイプ
export const RoleType = {
  SuperAdmin: process.env.VUE_APP_ROLE_SUPER_ADMIN, // システム管理者
  HeadAdmin: process.env.VUE_APP_ROLE_HEAD_ADMIN, // 本社管理者
  SalesAdmin: process.env.VUE_APP_ROLE_SALES_ADMIN, // 営業店
  AgencyAdmin: process.env.VUE_APP_ROLE_AGENCY_ADMIN, // 代理店（管理者）
  AgencyUser: process.env.VUE_APP_ROLE_AGENCY_USER, // 代理店（担当者）
};

// 権限順位
export const RolePriority = {
  [RoleType.SuperAdmin]: 1,
  [RoleType.HeadAdmin]: 2,
  [RoleType.SalesAdmin]: 3,
  [RoleType.AgencyAdmin]: 4,
  [RoleType.AgencyUser]: 5,
};

// 権限表示値
export const RoleTypeDisplay = {
  [RoleType.SuperAdmin]: i18n.t('label.roleType.superAdmin'),
  [RoleType.HeadAdmin]: i18n.t('label.roleType.headAdmin'),
  [RoleType.SalesAdmin]: i18n.t('label.roleType.salesAdmin'),
  [RoleType.AgencyAdmin]: i18n.t('label.roleType.agencyAdmin'),
  [RoleType.AgencyUser]: i18n.t('label.roleType.agencyUser'),
};

// 権限カテゴリーマップ
export const RoleTypeMap = {
  [OfficeType.Head]: [RoleType.SuperAdmin, RoleType.HeadAdmin],
  [OfficeType.Sales]: [RoleType.SalesAdmin],
  [OfficeType.Agency]: [RoleType.AgencyAdmin, RoleType.AgencyUser],
};

// 権限による条件
export const RoleCondition = {
  Type: {
    NotSelf: 11, // 自分以外のみ
    Sub: 12, // 自分以下の権限のみ
    NotSelfSub: 13, // 自分以外かつ自分以下の権限のみ
    Belongs: 21, // 所属店のみ
    NotBelongs: 22, // 所属店以外のみ
    BelongsData: 23, // 所属店に関するデータのみ
  },
};

// 権限による条件のマッピング
export const RoleConditionMap = {
  Accept: {
    editAgencyInfoForIndividual: {
      [RoleType.AgencyAdmin]: '*',
      [RoleType.AgencyUser]: '*',
    },
    noteEdit: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    baseInfoEdit: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    agencyInfoEdit: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    reflectContractInfo: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    selectAndEditSmsMailMessageBody: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    editPlanMessage: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    editPlanComment: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    sendMessage: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    filePrintAndDownload: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    extraFileUpload: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    statusChange: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
      [RoleType.AgencyUser]: RoleCondition.Type.BelongsData,
    },
    salesRegister: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
    salesBulkRegister: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
    salesEdit: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
    salesBulkEdit: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
    salesDelete: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
    agencyRegister: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
    },
    agencyBulkRegister: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
    agencyEdit: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
      [RoleType.AgencyAdmin]: RoleCondition.Type.Belongs,
    },
    agencyBulkEdit: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
    agencyDelete: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
    userRegister: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
      [RoleType.SalesAdmin]: '*',
    },
    userEdit: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: RoleCondition.Type.Sub,
      [RoleType.SalesAdmin]: RoleCondition.Type.Sub,
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
    },
    userDelete: {
      [RoleType.SuperAdmin]: RoleCondition.Type.NotSelf,
      [RoleType.HeadAdmin]: RoleCondition.Type.NotSelfSub,
    },
    userPasswordResend: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: RoleCondition.Type.Sub,
      [RoleType.SalesAdmin]: RoleCondition.Type.NotSelfSub,
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
    },
    userPasswordReset: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: RoleCondition.Type.Sub,
      [RoleType.SalesAdmin]: RoleCondition.Type.NotSelfSub,
      [RoleType.AgencyAdmin]: RoleCondition.Type.BelongsData,
    },
    userBulk: {
      [RoleType.SuperAdmin]: '*',
      [RoleType.HeadAdmin]: '*',
    },
  },
};

// 権限による条件のマッピング（項目）
export const RoleConditionMapItem = {
  Accept: {
    Read: {
      alertMailFlg: [RoleType.AgencyAdmin, RoleType.AgencyUser],
    },
    Update: {
      salesCode: [RoleType.SuperAdmin, RoleType.HeadAdmin],
      agencyCode: [
        RoleType.SuperAdmin,
        RoleType.HeadAdmin,
        RoleType.SalesAdmin,
      ],
      isDeleted: [RoleType.SuperAdmin, RoleType.HeadAdmin],
      comment: [RoleType.SuperAdmin, RoleType.HeadAdmin],
      alertMailFlg: [RoleType.AgencyAdmin, RoleType.AgencyUser],
    },
  },
};

// 案件検索結果 CSV
export const ContractSearchResultCsv = {
  maxRowCount: 10000, // 最大行数
  headers: [
    i18n.t('header.contractListTable.policyNumber'),
    i18n.t('header.contractListTable.previousPolicyNumber'),
    i18n.t('header.contractListTable.printingSerialNumber'),
    i18n.t('header.contractListTable.fullName'),
    i18n.t('header.contractListTable.fullNameKana'),
    i18n.t('header.contractListTable.startDate'),
    i18n.t('header.contractListTable.status'),
    i18n.t('header.contractListTable.startStatus'),
    i18n.t('header.contractListTable.note'),
    i18n.t('header.contractListTable.agencyCode'),
    i18n.t('header.contractListTable.agencyCodeUser'),
    i18n.t('header.contractListTable.salesCode'),
    i18n.t('header.contractListTable.chargePersonName'),
    i18n.t('header.contractListTable.latestUploadDate'),
  ],
  columns: [
    'policyNumber',
    'previousPolicyNumber',
    'printingSerialNumber',
    'fullName',
    'fullNameKana',
    'startDate',
    'status',
    'startStatus',
    'note',
    'contractAgencyCode',
    'agencyAgencyCode',
    'salesCode',
    'staffName',
    'latestUploadDate',
  ],
};

// メール/SMS利用状況集計 CSV
export const CostAggeregateCsv = {
  headers: [
    i18n.t('header.costAggeregateCsvHeader.agencyName'),
    i18n.t('header.costAggeregateCsvHeader.agencyCode'),
    i18n.t('header.costAggeregateCsvHeader.sendFlg'),
    i18n.t('header.costAggeregateCsvHeader.sendCode'),
    i18n.t('header.costAggeregateCsvHeader.count'),
    i18n.t('header.costAggeregateCsvHeader.totalPriceInUSD'),
  ],
  columns: [
    'agencyName',
    'agencyCode',
    'sendFlg',
    'sendCode',
    'count',
    'totalPriceInUSD',
  ],
};

// ユーザー情報集計　CSV
export const UserInformationAggregateCsv = {
  maxRowCount: 50000, // 一度に処理できる最大数
  headers: [
    i18n.t('header.userInformationAggregateCsvHeader.loginId'),
    i18n.t('header.userInformationAggregateCsvHeader.userName'),
    i18n.t('header.userInformationAggregateCsvHeader.userMail'),
    i18n.t('header.userInformationAggregateCsvHeader.salesCode'),
    i18n.t('header.userInformationAggregateCsvHeader.salesDepartmentName'),
    i18n.t('header.userInformationAggregateCsvHeader.salesSectionName'),
    i18n.t('header.userInformationAggregateCsvHeader.agencyCode'),
    i18n.t('header.userInformationAggregateCsvHeader.recruiterId'),
    i18n.t('header.userInformationAggregateCsvHeader.chargePersonName'),
    i18n.t('header.userInformationAggregateCsvHeader.chargePersonPhoneNumber'),
    i18n.t('header.userInformationAggregateCsvHeader.admin'),
    i18n.t('header.userInformationAggregateCsvHeader.role'),
    i18n.t('header.userInformationAggregateCsvHeader.created'),
    i18n.t('header.userInformationAggregateCsvHeader.lastLoginDate'),
    i18n.t('header.userInformationAggregateCsvHeader.comment'),
  ],
  columns: [
    'loginId',
    'userName',
    'userMail',
    'salesCode',
    'salesDepartmentName',
    'salesSectionName',
    'agencyCode',
    'recruiterId',
    'chargePersonName',
    'chargePersonPhoneNumber',
    'admin',
    'role',
    'created',
    'lastLoginDate',
    'comment',
  ],
};

// 代理店情報集計　CSV
export const AgencyInformationAggregateCsv = {
  maxRowCount: 50000, // 一度に処理できる最大数
  headers: [
    i18n.t('header.agencyInformationAggregateCsvHeader.salesCode'),
    i18n.t('header.agencyInformationAggregateCsvHeader.salesDepartmentName'),
    i18n.t('header.agencyInformationAggregateCsvHeader.salesSectionName'),
    i18n.t('header.agencyInformationAggregateCsvHeader.agencyCode'),
    i18n.t('header.agencyInformationAggregateCsvHeader.agencyName'),
    i18n.t('header.agencyInformationAggregateCsvHeader.created'),
  ],
  columns: [
    'salesCode',
    'salesDepartmentName',
    'salesSectionName',
    'agencyCode',
    'agencyName',
    'created',
  ],
};

// 営業店一括登録 CSV
export const SalesBulkRegisterCsv = {
  headers: {
    salesCode: i18n.t('label.salesBulkRegisterCsv.salesCode'),
    salesDepartmentName: i18n.t(
      'label.salesBulkRegisterCsv.salesDepartmentName'
    ),
    salesSectionName: i18n.t('label.salesBulkRegisterCsv.salesSectionName'),
  },
  columns: [
    {
      name: 'salesCode',
      type: 'text',
      rule: 'salesCodeRules',
    },
    {
      name: 'salesDepartmentName',
      type: 'text',
      rule: 'defaultRules',
    },
    {
      name: 'salesSectionName',
      type: 'text',
      rule: 'defaultRules',
    },
  ],
};

// 代理店一括登録 CSV
export const AgencyBulkRegisterCsv = {
  headers: {
    agencyCode: i18n.t('label.agencyBulkRegisterCsv.agencyCode'),
    agencyName: i18n.t('label.agencyBulkRegisterCsv.agencyName'),
    salesCode: i18n.t('label.agencyBulkRegisterCsv.salesCode'),
  },
  columns: [
    {
      name: 'agencyCode',
      type: 'text',
      rule: 'agencyCodeRules',
    },
    {
      name: 'agencyName',
      type: 'text',
      rule: 'defaultRules',
    },
    {
      name: 'salesCode',
      type: 'text',
      rule: 'salesCodeRules',
    },
  ],
};

// ユーザー一括登録 CSV
export const UserBulkRegisterCsv = {
  maxNumberAtOneTime: 500, // 一度に処理できる最大数
  headers: {
    loginId: i18n.t('label.userBulkRegisterCsv.loginId'),
    userName: i18n.t('label.userBulkRegisterCsv.userName'),
    userMail: i18n.t('label.userBulkRegisterCsv.userMail'),
    salesCode: i18n.t('label.userBulkRegisterCsv.salesCode'),
    agencyCode: i18n.t('label.userBulkRegisterCsv.agencyCode'),
    recruiterId: i18n.t('label.userBulkRegisterCsv.recruiterId'),
    chargePersonName: i18n.t('label.userBulkRegisterCsv.chargePersonName'),
    chargePersonPhoneNumber: i18n.t(
      'label.userBulkRegisterCsv.chargePersonPhoneNumber'
    ),
    startDate: i18n.t('label.userBulkRegisterCsv.startDate'),
    admin: i18n.t('label.userBulkRegisterCsv.admin'),
    comment: i18n.t('label.userBulkRegisterCsv.comment'),
  },
  columns: [
    {
      name: 'loginId',
      type: 'text',
      rule: 'defaultRules',
      importType: [
        UserBulkImportType.UpdateSales,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'userName',
      type: 'text',
      rule: 'userNameRules',
      importType: '*',
    },
    {
      name: 'userMail',
      type: 'text',
      rule: 'mailRules',
      importType: '*',
    },
    {
      name: 'salesCode',
      type: 'text',
      rule: 'salesCodeRules',
      importType: [
        UserBulkImportType.CreateSales,
        UserBulkImportType.UpdateSales,
      ],
    },
    {
      name: 'agencyCode',
      type: 'text',
      rule: 'agencyCodeRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'recruiterId',
      type: 'text',
      rule: 'recruiterIdRules',
      importType: '*',
    },
    {
      name: 'chargePersonName',
      type: 'text',
      rule: 'chargePersonNameRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'chargePersonPhoneNumber',
      type: 'text',
      rule: 'telRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'admin',
      type: 'number',
      rule: 'adminRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'startDate',
      type: 'date',
      rule: 'startDateRules',
      format: 'YYYY-MM-DD', // 日付フォーマット
      importType: [
        UserBulkImportType.CreateSales,
        UserBulkImportType.CreateAgency,
      ],
    },
    {
      name: 'comment',
      type: 'text',
      rule: 'commentdRules',
      importType: '*',
    },
  ],
};

// ユーザー出力 CSV
export const UserBulkExportCsv = {
  maxNumberAtOneTime: 10000, // 一度に処理できる最大数
  headers: {
    loginId: i18n.t('label.userBulkExportCsv.loginId'),
    userName: i18n.t('label.userBulkExportCsv.userName'),
    userMail: i18n.t('label.userBulkExportCsv.userMail'),
    salesCode: i18n.t('label.userBulkExportCsv.salesCode'),
    salesDepartmentName: i18n.t('label.userBulkExportCsv.salesDepartmentName'),
    salesSectionName: i18n.t('label.userBulkExportCsv.salesSectionName'),
    agencyCode: i18n.t('label.userBulkExportCsv.agencyCode'),
    recruiterId: i18n.t('label.userBulkExportCsv.recruiterId'),
    chargePersonName: i18n.t('label.userBulkExportCsv.chargePersonName'),
    chargePersonPhoneNumber: i18n.t(
      'label.userBulkExportCsv.chargePersonPhoneNumber'
    ),
    admin: i18n.t('label.userBulkExportCsv.admin'),
    lastLoginDateRules: i18n.t('label.userBulkExportCsv.lastLoginDateRules'),
    comment: i18n.t('label.userBulkExportCsv.comment'),
  },
  columns: [
    {
      name: 'loginId',
      text: 'ユーザーID',
      type: 'text',
      rule: 'defaultRules',
      importType: [
        UserBulkImportType.UpdateSales,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'userName',
      text: 'ユーザー名',
      type: 'text',
      rule: 'userNameRules',
      importType: '*',
    },
    {
      name: 'userMail',
      text: 'メールアドレス',
      type: 'text',
      rule: 'mailRules',
      importType: '*',
    },
    {
      name: 'salesCode',
      text: '部担コード',
      type: 'text',
      rule: 'salesCodeRules',
      importType: '*',
    },
    {
      name: 'salesDepartmentName',
      text: '部店名',
      type: 'text',
      rule: 'defaultRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'salesSectionName',
      text: '担当店名',
      type: 'text',
      rule: 'defaultRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'agencyCode',
      text: '代理店コード',
      type: 'text',
      rule: 'agencyCodeRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'recruiterId',
      text: '募集人ID',
      type: 'text',
      rule: 'recruiterIdRules',
      importType: '*',
    },
    {
      name: 'chargePersonName',
      text: '担当者名',
      type: 'text',
      rule: 'chargePersonNameRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'chargePersonPhoneNumber',
      text: '携帯番号',
      type: 'text',
      rule: 'telRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'admin',
      text: '管理者フラグ',
      type: 'number',
      rule: 'adminRules',
      importType: [
        UserBulkImportType.CreateAgency,
        UserBulkImportType.UpdateAgency,
      ],
    },
    {
      name: 'lastLoginDate',
      text: '最終ログイン日時',
      type: 'date',
      rule: 'defaultRules',
      format: 'YYYY-MM-DD', // 日付フォーマット
      importType: '*',
    },
    {
      name: 'comment',
      text: '履歴等コメント',
      type: 'text',
      rule: 'commentdRules',
      importType: '*',
    },
  ],
};

// 営業店検索結果CSV
export const SalesSearchResultCsv = {
  headers: [
    i18n.t('header.salesListTable.salesCode'),
    i18n.t('header.salesListTable.salesDepartmentName'),
    i18n.t('header.salesListTable.salesSectionName'),
  ],
  columns: ['salesCode', 'salesDepartmentName', 'salesSectionName'],
};

// 画像拡大倍率
export const imageScale = 2;

// 必要書類アップロード最大ファイルサイズ
export const uploadLimitFileSize = 5242880;

// ナビゲーションリンク
export const navigationLinks = [
  {
    to: '/',
    title: 'link.home',
    icon: 'home',
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
      RoleType.AgencyUser,
    ],
  },
  {
    title: 'link.masterManagement',
    icon: 'format_list_numbered',
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin, RoleType.SalesAdmin],
    grouplist: true,
    items: [
      {
        key: '0',
        to: '/sales_list',
        title: 'link.salesList',
        role: [RoleType.SuperAdmin, RoleType.HeadAdmin, RoleType.SalesAdmin],
      },
      {
        key: '1',
        to: '/agency_list',
        title: 'link.agencyList',
        role: [RoleType.SuperAdmin, RoleType.HeadAdmin, RoleType.SalesAdmin],
      },
      {
        key: '2',
        to: '/insurance_type_list',
        title: 'link.insuranceTypeList',
        role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
      },
      {
        key: '3',
        to: '/important_matter_list',
        title: 'link.importantMatterList',
        role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
      },
    ],
  },
  {
    title: 'link.accountManagement',
    icon: 'group',
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
    ],
    grouplist: true,
    items: [
      {
        key: '0',
        to: '/user_list',
        title: 'link.userList',
        role: [
          RoleType.SuperAdmin,
          RoleType.HeadAdmin,
          RoleType.SalesAdmin,
          RoleType.AgencyAdmin,
        ],
      },
    ],
  },
  {
    title: 'link.aggeregate',
    icon: 'insert_chart',
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
    grouplist: true,
    items: [
      {
        key: '0',
        to: '/cost_aggeregate',
        title: 'link.costAggeregate',
        role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
      },
      {
        key: '1',
        to: '/user_information_aggregate',
        title: 'link.userInformationAggregate',
        role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
      },
      {
        key: '2',
        to: '/agency_information_aggregate',
        title: 'link.agencyInformationAggregate',
        role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
      },
    ],
  },
  {
    title: 'link.registerLogoAndMessage',
    icon: 'insert_photo',
    role: [RoleType.AgencyAdmin],
    to: '/register_logo_and_message',
  },
  {
    title: 'link.editSendTemplateTextManagement',
    icon: 'mail_outline',
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
      RoleType.AgencyUser,
    ],
    to: '/send_template_text_managements/edit',
  },
];

// CSV 取込時の最大行数
export const MaxCsvImportRowCount = {
  Default: 10000,
};

// CSV 出力時の最大行数
export const MaxCsvExportRowCount = {
  Default: 10000,
};

// 文字列入力時の最大の長さ
export const MaxLength = {
  Default: 45,
  FileName: 100, // ファイル名
  MailAddress: 80, // メールアドレス
  AgencyName: 45, // 代理店名
  ChargePersonName: 45, // 担当者名
  AgencyComment: 500, // 代理店からのコメント(基本情報画面)
  AgencyMessage: 100, // 代理店からのメッセージ(ロゴ・メッセージ登録画面)
  PlanMessage: 100, // プランごとの必要書類関連情報
  PlanComment: 100, // プランごとの説明
  ContractNote: 150, // 案件ごとの備考
  RecruiterId: 10, // 募集人ID
  UserComment: 150, // ユーザーごとの履歴等コメント
  InsuranceTypeName: 45, // 種目名
  InsuranceTypeCode: 3, // 種目コード
  FormId: 5, // 帳票ID
  ImportantMatterName: 45, // 重要事項等説明書名
  ExtraFileComment: 50, // 申込内容以外データコメント
  SmsMessageBody: 600, // SMS送信用本文
  EmailMessageBody: 1500, // メール送信用本文
  CognitoPassword: 99, // cognito上のパスワード最大長
  SecretQuestionAnswer: 100, // 秘密の質問回答
  ModificationItem: 15, // 修正項目名の長さ
};

// SMS送信計算用
export const SentSms = {
  MaxCharactersWithOnlyOneSms: 70, // 1通のみの場合の最大の文字数（GSM 03.38 文字セット）
  MaxCharactersPerSms: 67, // 1通あたり最大の文字数（GSM 03.38 文字セット。2通以上の場合）
  FeePerSms: 8, // 1通あたりおおよその日本円（1通あたり$0.07451で課金される）
};

// 文字列入力時の最小の長さ
export const MinLength = {
  CognitoPassword: 8, // cognito上のパスワード最小長
};

// 送信方法
export const SenderFlg = {
  Mail: 1,
  Sms: 2,
  MailAndSms: 3,
};

// 送信方法文言
export const ReverseSenderFlg = {
  1: i18n.t('label.mail'),
  2: i18n.t('label.sms'),
  3: i18n.t('label.mailAndSms'),
};

// 送信管理番号文言
export const ReverseSendCode = {
  11: i18n.t('label.sendTriger.contractor.proposal'), // 提案
  12: i18n.t('label.sendTriger.contractor.concluded'), // 締結済
  15: i18n.t('label.sendTriger.contractor.auth'), // 認証メール
  16: i18n.t('label.sendTriger.contractor.withdrawalBeforeConcluded'), // 取り下げ (締結前)
  17: i18n.t('label.sendTriger.contractor.withdrawalAfterConcluded'), // 取り下げ (締結後)
  21: i18n.t('label.sendTriger.agency.concluded'), // 締結済
};

// 認証方法
export const AuthType = {
  policyNumber: 1,
  dateOfBirth: 2,
};

// 認証方法
export const ReverseAuthType = {
  1: 'policyNumber',
  2: 'dateOfBirth',
};

// HTTPステータス
export const HttpStatus = {
  Success: 200,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  Locked: 423,
  InternalError: 500,
  Maintenance: 503,
};

// 最大数
export const MaxCount = {
  ModificationItems: 10,
  Fix: 3,
  SjNetConnectCount: 3,
  loginError: 5, // ログイン失敗許容回数
};

// 最大検索件数
export const MaxSearchResult = {
  AgencyConnection: 1000,
};

// 秘密の質問一覧
export const SecretQuestions = [
  {
    text: i18n.t('label.secretQuestionList.pet'),
    value: 1,
  },
  {
    text: i18n.t('label.secretQuestionList.elementarySchool'),
    value: 2,
  },
  {
    text: i18n.t('label.secretQuestionList.respect'),
    value: 3,
  },
  {
    text: i18n.t('label.secretQuestionList.favoriteFood'),
    value: 4,
  },
  {
    text: i18n.t('label.secretQuestionList.nickname'),
    value: 5,
  },
  {
    text: i18n.t('label.secretQuestionList.overseasTravel'),
    value: 6,
  },
  {
    text: i18n.t('label.secretQuestionList.placeToLive'),
    value: 7,
  },
];

// 契約者ロックアウトステータス
export const ContractorLockoutStatus = {
  NotLocked: 1, // 有効
  Locked: 2, // ロックアウト
  MaximumReSent: 3, // 最大再送信数到達
};

// 文字色管理
export const TextStyle = {
  Alert: ['red--text text--lighten-1'], // アラート
  Warring: ['orange--text text--lighten-1'], // 警告
};

// 商品名
export const ProductName = {
  Bk: 'BK', // 個人用火災総合保険
  Bl: 'BL', // 個人用火災総合保険（債務者集団扱）
};

// 保険対象
export const InsuranceObject = {
  Building: 1, // 建物
  HouseholdProperty: 3, // 家財
  OutdoorEquipment: 'A', // 屋外設備装置
  HouseholdPropertyHousedInOutdoorEquipment: 'B', // 屋外設備に収容されている家財
  HouseholdPropertyInOpenAirStorage: 'C', // 野積みの家財
  SpecificHouseholdProperty: 'D', // 特定の家財
};

// 保険対象の表示値
export const InsuranceObjectDisplay = {
  [InsuranceObject.Building]: i18n.t('label.insuranceObject.building'),
  [InsuranceObject.HouseholdProperty]: i18n.t(
    'label.insuranceObject.householdProperty'
  ),
  [InsuranceObject.OutdoorEquipment]: i18n.t(
    'label.insuranceObject.OutdoorEquipment'
  ),
  [InsuranceObject.HouseholdPropertyHousedInOutdoorEquipment]: i18n.t(
    'label.insuranceObject.HouseholdPropertyHousedInOutdoorEquipment'
  ),
  [InsuranceObject.HouseholdPropertyInOpenAirStorage]: i18n.t(
    'label.insuranceObject.HouseholdPropertyInOpenAirStorage'
  ),
  [InsuranceObject.SpecificHouseholdProperty]: i18n.t(
    'label.insuranceObject.SpecificHouseholdProperty'
  ),
};

// 初回保険料払込方法（値はindex番号）
export const FirstPayment = {
  AccountTransfer: 'accountTransferFlg', // 口座振替
  CreditCard: 'creditCardFlg', // クレジットカード払（即時）
  PaymentSlip: 'paymentSlipFlg', // 払込票払
  CashPayment: 'cashPaymentFlg', // その他払込
  DebitCard: 'debitCardFlg', // デビットカード払
};

// 初回保険料払込方法の表示値
export const FirstPaymentDisplay = {
  [FirstPayment.AccountTransfer]: i18n.t('label.firstPayment.accountTransfer'),
  [FirstPayment.CreditCard]: i18n.t('label.firstPayment.creditCard'),
  [FirstPayment.PaymentSlip]: i18n.t('label.firstPayment.paymentSlip'),
  [FirstPayment.CashPayment]: i18n.t('label.firstPayment.cashPayment'),
  [FirstPayment.DebitCard]: i18n.t('label.firstPayment.debitCard'),
};

// 基本保険期間のデータの長さ
export const BaseInsurancePeriodLength = {
  OnlyYear: 2, // 年のみの場合
  OnlyMonthOrDay: 4, // 月日のみの場合
  Both: 6, // 年と月日両方存在の場合
  MonthFlg: 'M', // 月間のフラグ
  DayFlg: 'D', // 日間のフラグ
};

// 払込方法
export const Payment = {
  Once: '0', // 一括払
  Monthly: '1', // 月払
  LongTermMonth: '2', // 長期月払
  LongTermYearly: '3', // 長期年払
  LongTermOnce: '4', // 長期一括払
  OrganizationTwelve: '5', // 団体扱12回
  OrganizationOnce: '6', // 団体扱一括
  GroupTwelve: '7', // 集団扱12回
  GroupOnce: '8', // 集団扱一括
  OrganizationLoneTermYearly: '9', // 団体長期年払
  GroupLoneTermOnce: 'A', // 集団扱長期一括払
  GroupLoneTermYearly: 'B', // 集団扱長期年
};

// 払込方法の表示値
export const PaymentDisplay = {
  [Payment.Once]: i18n.t('label.payment.once'),
  [Payment.Monthly]: i18n.t('label.payment.monthly'),
  [Payment.LongTermMonth]: i18n.t('label.payment.longTermMonth'),
  [Payment.LongTermYearly]: i18n.t('label.payment.longTermYearly'),
  [Payment.LongTermOnce]: i18n.t('label.payment.longTermOnce'),
  [Payment.OrganizationTwelve]: i18n.t('label.payment.organizationTwelve'),
  [Payment.OrganizationOnce]: i18n.t('label.payment.organizationOnce'),
  [Payment.GroupTwelve]: i18n.t('label.payment.groupTwelve'),
  [Payment.GroupOnce]: i18n.t('label.payment.groupOnce'),
  [Payment.OrganizationLoneTermYearly]: i18n.t(
    'label.payment.organizationLoneTermYearly'
  ),
  [Payment.GroupLoneTermOnce]: i18n.t('label.payment.groupLoneTermOnce'),
  [Payment.GroupLoneTermYearly]: i18n.t('label.payment.groupLoneTermYearly'),
};

// 現職・退職区分(2020/10/16追加要望より、画面表示しないがデータとして保持する)
export const EmploymentStatus = {
  Incumbent: 1, // 現職
  Retiree: 2, // 退職者
};

// 地震保険期間の区分コード
export const EarthquakeInsurancePeriodTypeCode = {
  Same: 1, // 基本の始期から基本の終期まで
  OneYear: 2, // 基本の始期から１年間以降、１年ごと自動継続
  FiveYears: 3, // 基本の始期から５年間以降、５年ごと自動継続
};

// 必要書類関連情報のコード
export const attachDocCode = {
  code01: '01',
  code02: '02',
  code03: '03',
  code04: '04',
  code05: '05',
  code06: '06',
  code07: '07',
  code08: '08',
  code09: '09',
  code10: '10',
  code11: '11',
};

// 必要書類関連情報のコードの表示値
export const attachDocCodeDisplay = {
  [attachDocCode.code01]: i18n.t('label.attachDocCode.code01'),
  [attachDocCode.code02]: i18n.t('label.attachDocCode.code02'),
  [attachDocCode.code03]: i18n.t('label.attachDocCode.code03'),
  [attachDocCode.code04]: i18n.t('label.attachDocCode.code04'),
  [attachDocCode.code05]: i18n.t('label.attachDocCode.code05'),
  [attachDocCode.code06]: i18n.t('label.attachDocCode.code06'),
  [attachDocCode.code07]: i18n.t('label.attachDocCode.code07'),
  [attachDocCode.code08]: i18n.t('label.attachDocCode.code08'),
  [attachDocCode.code09]: i18n.t('label.attachDocCode.code09'),
  [attachDocCode.code10]: i18n.t('label.attachDocCode.code10'),
  [attachDocCode.code11]: i18n.t('label.attachDocCode.code11'),
};

// 質権
export const RightOfPledge = {
  Presence: 1, // 質権あり
  Absence: 2, // 質権なし
};

// SJNET連携偉コード
export const SjNetError = {
  accessToken: {
    decryptedError: {
      code: 'sj1001',
      title: i18n.t('title.agency.sjNetError.accessToken.decryptedError'),
      description: i18n.t('error.sjNetError.accessToken.decryptedError'),
    },
    noResponse: {
      code: 'sj1002',
      title: i18n.t('title.agency.sjNetError.accessToken.notConnection'),
      description: i18n.t('error.sjNetError.accessToken.notConnection'),
    },
    authError: {
      code: 'sj1003',
      title: i18n.t('title.agency.sjNetError.accessToken.notConnection'),
      description: i18n.t('error.sjNetError.accessToken.notConnection'),
    },
    timeout: {
      code: 'sj1004',
      title: i18n.t('title.agency.sjNetError.accessToken.timeout'),
      description: i18n.t('error.sjNetError.accessToken.timeout'),
    },
    internalServerError: {
      code: 'sj1005',
      title: i18n.t('title.agency.sjNetError.accessToken.notConnection'),
      description: i18n.t('error.sjNetError.accessToken.notConnection'),
    },
    maintenance: {
      code: 'sj1006',
      title: i18n.t('title.agency.sjNetError.accessToken.maintenance'),
      description: i18n.t('error.sjNetError.accessToken.maintenance'),
    },
    unexpectedError: {
      code: 'sj1007',
      title: i18n.t('title.agency.sjNetError.accessToken.notConnection'),
      description: i18n.t('error.sjNetError.accessToken.notConnection'),
    }
  },
  getContract: {
    noResponse: {
      code: 'sj2001',
      title: i18n.t('title.agency.sjNetError.getContract.contractError'),
      description: i18n.t('error.sjNetError.getContract.getContractError'),
    },
    authError: {
      code: 'sj2002',
      title: i18n.t('title.agency.sjNetError.getContract.contractError'),
      description: i18n.t('error.sjNetError.getContract.getContractError'),
    },
    timeout: {
      code: 'sj2003',
      title: i18n.t('title.agency.sjNetError.getContract.timeout'),
      description: i18n.t('error.sjNetError.getContract.timeout'),
    },
    internalServerError: {
      code: 'sj2004',
      title: i18n.t('title.agency.sjNetError.getContract.contractError'),
      description: i18n.t('error.sjNetError.getContract.getContractError'),
    },
    maintenance: {
      code: 'sj2005',
      title: i18n.t('title.agency.sjNetError.getContract.maintenance'),
      description: i18n.t('error.sjNetError.getContract.maintenance'),
    },
    unexpectedError: {
      code: 'sj2006',
      title: i18n.t('title.agency.sjNetError.getContract.contractError'),
      description: i18n.t('error.sjNetError.getContract.getContractError'),
    },
    validateError: {
      code: 'sj2007',
      title: i18n.t('title.agency.sjNetError.getContract.contractError'),
      description: i18n.t('error.sjNetError.getContract.validateError'),
    },
    notFoundAgency: {
      code: 'sj2008',
      title: i18n.t('title.agency.sjNetError.getContract.contractError'),
      description: i18n.t('error.sjNetError.getContract.notFoundAgency'),
    },
    notFoundInsuranceType: {
      code: 'sj2009',
      title: i18n.t('title.agency.sjNetError.getContract.contractError'),
      description: i18n.t('error.sjNetError.getContract.notFoundInsuranceType'),
    },
    duplicateContractPolicyNumberAndPrintingSerialNumber: {
      code: 'sj2010',
      title: i18n.t('title.agency.sjNetError.getContract.contractError'),
      description: i18n.t('error.sjNetError.getContract.duplicateContractPolicyNumberAndPrintingSerialNumberError'),
    }
  }
};

// 申込内容以外データプレビュー対象拡張子一覧
export const ExtensionsAllowedInExtraPicture = ['jpeg', 'jpg', 'gif', 'png'];
