var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        app: "",
        clipped: "",
        "mobile-breakpoint": _vm.breakPoint,
        touchless: true,
        width: "300"
      },
      model: {
        value: _vm.inputValue,
        callback: function($$v) {
          _vm.inputValue = $$v
        },
        expression: "inputValue"
      }
    },
    [
      _c("v-spacer", { staticClass: "mt-2" }),
      _c(
        "v-list",
        { attrs: { dense: "", nav: "" } },
        [
          _c(
            "v-list-item-group",
            {
              model: {
                value: _vm.index,
                callback: function($$v) {
                  _vm.index = $$v
                },
                expression: "index"
              }
            },
            [
              _vm._l(_vm.links, function(link) {
                return [
                  link.grouplist
                    ? [
                        _c(
                          "v-list-group",
                          {
                            key: link.title,
                            attrs: {
                              "prepend-icon": link.icon,
                              value: false,
                              type: link.type,
                              role: link.role
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function() {
                                    return [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(link.title))
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          _vm._l(link.items, function(item) {
                            return _c(
                              "v-list-item",
                              { key: item.key, attrs: { to: item.to } },
                              [
                                _c("v-list-item-icon"),
                                _c("v-list-item-title", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t(item.title))
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ]
                    : [
                        _c(
                          "v-list-item",
                          {
                            key: link.title,
                            attrs: {
                              to: link.to,
                              type: link.type,
                              role: link.role
                            }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v(_vm._s(link.icon))])],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(link.title))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }