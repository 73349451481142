var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { "clipped-left": "", color: "header", height: "auto" } },
    [
      _c("v-app-bar-nav-icon", {
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.toggleDrawer.apply(null, arguments)
          }
        }
      }),
      _c("v-img", {
        attrs: {
          src: require("@/assets/img/logo.gif"),
          "max-width": "170px",
          "min-width": "170px"
        }
      }),
      _c(
        "v-col",
        [
          _c("v-row", { staticClass: "pl-4", attrs: { justify: "end" } }, [
            _vm._v(_vm._s(_vm.officeName))
          ])
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c("v-row", { staticClass: "pl-4", attrs: { justify: "end" } }, [
            _vm._v(_vm._s(_vm.$t("label.insurenceStartdate")))
          ])
        ],
        1
      ),
      _c(
        "v-toolbar-items",
        [
          _c(
            "v-menu",
            {
              attrs: {
                bottom: "",
                left: "",
                "offset-y": "",
                transition: "slide-y-transition"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          { staticClass: "text-none", attrs: { text: "" } },
                          on
                        ),
                        [
                          _c("v-icon", [_vm._v("mdi-account")]),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.userName) +
                              "\n          "
                          ),
                          _c("v-icon", [_vm._v("keyboard_arrow_down")])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.toolbarLinks, function(link) {
                  return _c(
                    "v-list-item",
                    { key: link.title, on: { click: link.click } },
                    [_c("v-list-item-title", [_vm._v(_vm._s(link.name))])],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "font-weight-black float-right mr-4",
          staticStyle: { "font-size": "15px" },
          attrs: {
            align: "center",
            width: "70vw",
            "max-width": "100px",
            color: "next",
            dark: ""
          },
          on: { click: _vm.onLogoutClick }
        },
        [_vm._v(_vm._s(_vm.$t("label.logout")))]
      ),
      _c("v-img", {
        staticClass: "float-right",
        attrs: {
          src: require("@/assets/img/pdMark.gif"),
          "max-width": "30px",
          "min-width": "30px",
          height: "auto"
        }
      }),
      _c("PasswordChangeDialog", {
        ref: "passwordChangeDialog",
        on: { onSuccess: _vm.onSuccessPasswordChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }