var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass: "mt-2",
      staticStyle: { "font-size": "12px", color: "#f55a4e" }
    },
    [_vm._v("\n  " + _vm._s(_vm.error) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }