var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.$t("label.passwordUpdate"), color: "next" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("button.cancel")) + "\n    "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.onClickUpdateButton()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.update")))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        { staticClass: "pt-0 px-8" },
        [
          _c(
            "ValidationObserver",
            { ref: "observer" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _vm.isForce && _vm.isForceCreateSecretQuestion
                    ? [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { class: _vm.conditionStyle },
                              [
                                _c("ValidationProvider", {
                                  attrs: { rules: `selectRequired` },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function({ errors }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                "item-text": "text",
                                                "item-value": "value",
                                                items: _vm.SecretQuestions,
                                                value:
                                                  _vm.secretQuestion.questionId,
                                                "hide-details": "",
                                                label: _vm.$t(
                                                  "label.secretQuestion"
                                                )
                                              },
                                              model: {
                                                value:
                                                  _vm.secretQuestion.questionId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.secretQuestion,
                                                    "questionId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "secretQuestion.questionId"
                                              }
                                            }),
                                            _c("ValidationError", {
                                              staticClass: "ma-0",
                                              attrs: { error: errors[0] }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2650655015
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { class: _vm.conditionStyle },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: `required|max:${_vm.MaxLength.SecretQuestionAnswer}`
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function({ errors }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                "hide-details": "",
                                                label: _vm.$t("label.answer")
                                              },
                                              model: {
                                                value:
                                                  _vm.secretQuestion.answer,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.secretQuestion,
                                                    "answer",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "secretQuestion.answer"
                                              }
                                            }),
                                            _c("ValidationError", {
                                              staticClass: "ma-0",
                                              attrs: { error: errors[0] }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2666950101
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isForce,
                          expression: "!isForce"
                        }
                      ]
                    },
                    [
                      _c(
                        "v-col",
                        { class: _vm.conditionStyle },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: `required|min:${_vm.MinLength.CognitoPassword}|max:${_vm.MaxLength.CognitoPassword}`,
                              name: "currentPassword"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ errors }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        "hide-details": "",
                                        type: "Password",
                                        label: _vm.$t(
                                          "label.editUserInfo.currentPassword"
                                        )
                                      },
                                      model: {
                                        value: _vm.currentPassword,
                                        callback: function($$v) {
                                          _vm.currentPassword = $$v
                                        },
                                        expression: "currentPassword"
                                      }
                                    }),
                                    _c("ValidationError", {
                                      staticClass: "ma-0",
                                      attrs: { error: errors[0] }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0 mb-0 py-0" },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: `required|requiredHalfNumber|requiredHalfUppercase|requiredHalfLowercase|min:${_vm.MinLength.CognitoPassword}|checkIsSameAsTheCurrentPassword:@currentPassword|max:${_vm.MaxLength.CognitoPassword}`,
                              name: "newPassword"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ errors }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        "hide-details": "",
                                        type: "Password",
                                        label: _vm.$t(
                                          "label.editUserInfo.newPassword"
                                        )
                                      },
                                      model: {
                                        value: _vm.newPassword,
                                        callback: function($$v) {
                                          _vm.newPassword = $$v
                                        },
                                        expression: "newPassword"
                                      }
                                    }),
                                    _c("ValidationError", {
                                      staticClass: "ma-0",
                                      attrs: { error: errors[0] }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-row", { staticClass: "my-0 py-0" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.passwordValidationRule")) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { class: _vm.conditionStyle },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: `required|checkIsSameAsTheNewPassword:@newPassword|max:${_vm.MaxLength.CognitoPassword}`
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ errors }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        "hide-details": "",
                                        type: "Password",
                                        label: _vm.$t(
                                          "label.editUserInfo.newPasswordConfirm"
                                        )
                                      },
                                      model: {
                                        value: _vm.newPasswordConfirm,
                                        callback: function($$v) {
                                          _vm.newPasswordConfirm = $$v
                                        },
                                        expression: "newPasswordConfirm"
                                      }
                                    }),
                                    _c("ValidationError", {
                                      staticClass: "ma-0",
                                      attrs: { error: errors[0] }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ErrorDialog", { ref: "errorDialog" }),
      _c("CompletedDialog", {
        ref: "completedDialog",
        on: { onSuccess: _vm.onComplete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }