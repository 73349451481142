import { RoleType } from '@/lib/const';

export default [
  {
    path: '/',
    name: 'Home',
    view: 'Home',
    meta: { requiredAuth: true },
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
      RoleType.AgencyUser,
    ],
  },
  {
    path: '/login',
    name: 'Login',
    view: 'Login',
    meta: { requiredAuth: false },
  },
  {
    path: '/contract_detail/:contractId',
    name: 'ContractDetail',
    view: 'ContractDetail',
    meta: { requiredAuth: true },
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
      RoleType.AgencyUser,
    ],
  },
  {
    path: '/contract_detail/:contractId/important_matter',
    name: 'ContractImportantMatterPreview',
    view: 'ContractImportantMatterPreview',
    meta: { requiredAuth: true },
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
      RoleType.AgencyUser,
    ],
  },
  {
    path: '/contract_detail/:contractId/application_form',
    name: 'ApplicationFormPreview',
    view: 'ApplicationFormPreview',
    meta: { requiredAuth: true },
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
      RoleType.AgencyUser,
    ],
  },
  {
    path: '/register_logo_and_message',
    name: 'RegisterLogoAndMessage',
    view: 'RegisterLogoAndMessage',
    meta: { requiredAuth: true },
    role: [RoleType.AgencyAdmin],
  },
  {
    path: '/insurance_type_list',
    name: 'InsuranceTypeList',
    view: 'InsuranceTypeList',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
  },
  {
    path: '/important_matter_list',
    name: 'ImportantMatterList',
    view: 'ImportantMatterList',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
  },
  {
    path: '/internet_covenant_list',
    name: 'InternetCovenantList',
    view: 'InternetCovenantList',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
  },
  {
    path: '/sales_list',
    name: 'SalesList',
    view: 'SalesList',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin, RoleType.SalesAdmin],
  },
  {
    path: '/sales_detail/:sales_id',
    name: 'SalesDetail',
    view: 'SalesDetail',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin, RoleType.SalesAdmin],
  },
  {
    path: '/agency_list',
    name: 'AgencyList',
    view: 'AgencyList',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin, RoleType.SalesAdmin],
  },
  {
    path: '/agency_detail/:agency_id',
    name: 'AgencyDetail',
    view: 'AgencyDetail',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin, RoleType.SalesAdmin],
  },
  {
    path: '/user_list',
    name: 'UserList',
    view: 'UserList',
    meta: { requiredAuth: true },
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
    ],
  },
  {
    path: '/user_detail/:user_id',
    name: 'UserDetail',
    view: 'UserDetail',
    meta: { requiredAuth: true },
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
    ],
  },
  {
    path: '/important_matter/:importantMatterId',
    name: 'ImportantMatterPreview',
    view: 'ImportantMatterPreview',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
  },
  {
    path: '/edit_user_info/me',
    name: 'EditUserInfo',
    view: 'EditUserInfo',
    meta: { requiredAuth: true },
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
      RoleType.AgencyUser,
    ],
  },
  {
    path: '/edit_agency_info_for_individual/me',
    name: 'EditAgencyInfoForIndividual',
    view: 'EditAgencyInfoForIndividual',
    meta: { requiredAuth: true },
    role: [RoleType.AgencyAdmin, RoleType.AgencyUser],
  },
  {
    path: '/cost_aggeregate',
    name: 'CostAggeregate',
    view: 'CostAggeregate',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
  },
  {
    path: '/user_information_aggregate',
    name: 'userInformationAggregate',
    view: 'UserInformationAggregate',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
  },
  {
    path: '/agency_information_aggregate',
    name: 'agencyInformationAggregate',
    view: 'AgencyInformationAggregate',
    meta: { requiredAuth: true },
    role: [RoleType.SuperAdmin, RoleType.HeadAdmin],
  },
  {
    path: '/send_template_text_managements/edit',
    name: 'EditSendTemplateTextManagement',
    view: 'EditSendTemplateTextManagement',
    meta: { requiredAuth: true },
    role: [
      RoleType.SuperAdmin,
      RoleType.HeadAdmin,
      RoleType.SalesAdmin,
      RoleType.AgencyAdmin,
      RoleType.AgencyUser,
    ],
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    view: 'Maintenance',
    meta: { requiredAuth: false },
  },
];
